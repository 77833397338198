import axios from 'axios';
import { UniAdapter } from 'uniapp-axios-adapter';
import {
  getLocalData,
  getToken,
  toast,
  cleanToken,
  gotoPage,
  gotoLogin,
  getCurPage,
  hideLoading,
  getQueryString,
  checkPlatform,
  md5,
} from '@util';
import { useCommonStore } from '@stores/common';
import { default as config, handleChannel } from '@config';

const platform = checkPlatform();
// 函数返回唯一的请求key
function getRequestKey(config) {
  let { method, url, params, data } = config;
  return [method, url, JSON.stringify(params), JSON.stringify(data)].join('&');
}

// 添加请求信息
let pendingRequest = new Map();
function addPendingRequest(config) {
  let requestKey = getRequestKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
}

// 取消重复请求，移除重复请求信息
function removePendingRequest(config) {
  let requestKey = getRequestKey(config);
  if (pendingRequest.has(requestKey)) {
    // 如果是重复的请求，则执行对应的cancel函数
    let cancel = pendingRequest.get(requestKey);
    cancel(requestKey);
    // 将前一次重复的请求移除
    pendingRequest.delete(requestKey);
  }
}

const request = axios.create({
  baseURL: config._apiPath,
  timeout: 60000,
  responseType: 'json',
  adapter: UniAdapter,
});

request.defaults.headers.post['content-type'] = 'application/json';
request.defaults.headers.get['content-type'] = 'application/x-www-form-urlencoded';
request.defaults.headers.delete['content-type'] = 'application/x-www-form-urlencoded';
// request.defaults.headers.put["content-type"] ="application/x-www-form-urlencoded";
request.defaults.headers.put['content-type'] = 'application/json';
request.defaults.headers.patch['content-type'] = 'application/x-www-form-urlencoded';

function handleConfig(configParams) {
  const store = useCommonStore();
  const sys = uni.getSystemInfoSync();
  const handleChannelRes = handleChannel();

  let _config = configParams || {};
  _config.headers['content-language'] = store.checkSiteConfigRes?.lang || getLocalData('lang') || '';
  _config.headers['tenant-id'] = store.checkSiteConfigRes?.nationCode || getLocalData('nationCode') || '';
  _config.headers['gray-version'] = getLocalData('gray-version') || '1.0.0'; // 灰度发布的参数
  _config.headers['content-sa'] = config._hostName; // 请求来源

  //#ifdef APP-PLUS
  _config.headers['channel-id'] = handleChannelRes.channel; // 请求来源
  // #endif
  //#ifdef H5
  if (getQueryString('siteType') === 'w2a' || getLocalData('siteType') === 'w2a') {
    _config.headers['channel-id'] = getLocalData('channel') || getQueryString('channel');
  } else {
    _config.headers['channel-id'] = 'h5';
  }
  // #endif

  let token = getToken();
  // console.log('---token', token);
  if (token) {
    _config.headers['Authorization'] = `Bearer ${token}`;
  }

  let timeZoneId = getLocalData('time-zone-id');
  if (!timeZoneId) {
    // 获取时区信息转换为 "GMT+/-X" 格式
    const offset = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(offset / 60));
    timeZoneId = `GMT${offset >= 0 ? '-' : '+'}${offsetHours}`;
  }
  _config.headers['time-zone-id'] = timeZoneId;

  if (_config.url.indexOf('upload') >= 0) {
    _config.headers['content-type'] = 'multipart/form-data';
  }
  _config.headers['platform-id'] = store.checkSiteConfigRes?.siteCode || getLocalData('siteCode') || '';
  _config.headers['app-version'] = `${sys.appVersion}_${sys.appVersionCode}`; // 版本号
  //#ifdef APP-PLUS
  _config.headers['client-type'] = plus.os.name == 'iOS' ? 4 : 3;
  // #endif
  //#ifdef H5
  if (getQueryString('siteType') === 'w2a' || getLocalData('siteType') === 'w2a') {
    _config.headers['client-type'] = 5;
  } else if (platform === 'TMA') {
    _config.headers['client-type'] = 6;
  } else {
    _config.headers['client-type'] = 1;
  }
  // #endif
  // _config.headers['appId'] = sys.appId // appid
  // _config.headers['innerVersion'] = sys.appVersionCode  // 内部版本号
  // // #ifdef APP-PLUS
  // _config.headers['channelId'] = plus.runtime.channel // 渠道
  // // #endif

  // console.log("+request-url：", _config.method, _config.url);
  // console.log("+request-params：", _config.params || _config.data);
  // console.log('---config+++', _config);
  return _config;
}

request.interceptors.request.use(
  (config) => {
    // #ifdef H5
    // TODO: 首次安装app无法启动
    // 检查是否存在重复请求，若存在则取消已发的请求
    // removePendingRequest(config);
    // 把当前请求信息添加到pendingRequest对象中
    // addPendingRequest(config);
    // #endif
    return handleConfig(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    // 从pendingRequest对象中移除请求
    removePendingRequest(response.config);

    const config = response.config;
    const resConfigData = config.data ? JSON.parse(config.data) : '';
    if (response && (response.status === 200 || response.status === 304)) {
      const res = response.data;
      if (res.code == 0) {
        const resUrl = response.config.url; // response.request.responseURL
        if (resUrl.indexOf('queryDeadActivity') >= 0 || resUrl.indexOf('getRequestInfo') >= 0) {
          // 只这个接口返回系统时间
          return { systemTime: res.systemTime, data: res.data };
        }
        return res.data;
      } else {
        if (resConfigData && resConfigData.__flag && resConfigData.__flag == 'noReport') {
          // 上报日志接口不处理,单独在接口请求回调中处理。
        } else {
          // hideLoading()
          errorCodeHandle(res, response.config.url);
          // 其他页面需要显示错误信息
        }
        return Promise.reject(response.data);
      }
    } else if ([500, 501, 502, 503, 504, 521].includes(response.status)) {
      // if (resConfigData && resConfigData.__flag && resConfigData.__flag == "noReport") { // 上报日志接口不处理,单独在接口请求回调中处理。
      //   return Promise.reject(response.data);
      // } else {
      gotoPage({ page: 'notice' });
      // }
    } else {
      // http状态码错误
      // hideLoading()
      toast('Error a la conexión de Internet, intenta de nuevo.'); //网络异常，请稍后重试！
      return Promise.reject(response.data);
    }
  },
  (error) => {
    // 从pendingRequest对象中移除请求
    removePendingRequest(error.config || {});
    if (axios.isCancel(error)) {
      // console.log("已取消的重复请求：" + error.message);
    } else {
      // 添加异常处理
      // 无网络的情况
      // hideLoading()
      // toast('Error a la conexión de Internet, intenta de nuevo.'); //网络异常，请稍后重试！
      return Promise.reject(error);
    }
  }
);

function errorCodeHandle(res, url) {
  switch (res.code) {
    case 401:
      // 登录状态过期，需要重新登录
      // TODO:切换一下登录状态以及重置userInfo
      hideLoading(); // 防止报错，loading一直存在的情况
      console.log('......request:401 clean......');
      cleanToken();
      const store = useCommonStore();
      store.switchLogin(false);

      if (platform !== 'TMA') {
        gotoLogin();
      }

      break;
    case 600:
      console.log('600,router:notice');
      gotoPage({ page: 'notice' });
      break;
    case 1004:
    case 2006:
    case 3101:
    case 3102:
      let $pageFullPath = '';
      // #ifdef H5
      $pageFullPath = location.pathname;
      // #endif

      // #ifdef APP-PLUS
      let pages = getCurrentPages();
      let $page = pages[pages.length - 1];
      $pageFullPath = $page && $page.$page && $page.$page.fullPath; // uniapp当前页面路径
      // #endif

      if ($pageFullPath && ['/pages/main', '/pages/h5fb'].includes($pageFullPath)) {
        // 跳转到404,处理不允许访问重复跳转bug
        return;
      } else {
        // gotoPage({ page: "main" });
        uni.reLaunch({
          url: '/pages/main',
        });
      }
      break;
    default:
      console.log(`${url}-|-${res.code}: ${res.msg}`);
      // toast(res.msg)
      // return Promise.reject(res)
      break;
  }
}

/**
 * 封装get请求
 */
request.$get = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    request
      .get(url, { params: data })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * 封装post请求
 */
request.$post = (url, data = {}, config) => {
  return new Promise((resolve, reject) => {
    request
      .post(url, data, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 *  封装patch请求
 */
request.$patch = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    request
      .patch(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 *  封装put请求
 */
request.$put = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    request
      .put(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 *  封装delete请求
 */
request.$del = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    request
      .delete(url, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default request;
